import { create } from 'zustand';
import config from "../config.json";
import api from '../lib/api';
import events from "../lib/events";
import useHallowenStore from './halloweenStore';
import useUserStore from './userStore';

async function uploadFishy({aquariumId, connectToken, blob, maskUrl}) {
  try {
    let res;
    if (aquariumId){
      res = await api.uploadFishy(aquariumId, blob, maskUrl);
      events.track(`Fishy uploaded`, {aquariumId, via: 'aquariumId'});
    }
    else if (connectToken)
      res = await api.uploadFishyConnectToken(connectToken, blob, maskUrl);

    if (res.status == '429') {
      events.track(`Fishy upload error`, {connectToken, via: 'connectToken', error: 'rate-limit'});
      return {error: 'rate-limit', message: `rate limit exceeded`}
    }
    events.track(`Fishy uploaded`, {connectToken, via: 'connectToken'});
  }
  catch (ex) {
    if (ex.response?.status === 500 && ex.response?.data?.code === 'aquarium_limit'){
      events.track(`Fishy upload error`, {error: 'aquarium-limit'});
      return {error: 'aquarium-limit', message: 'aquarium limit exceeded'}
    }
    events.track(`Fishy upload error`, {error: ex.response?.statusText});
    return {error: ex.response?.status || true, message: ex.response?.data || ex.response?.statusText || `Error: ${ex}`}
  }
  return {}
}


const useFishyStore = create((set) => ({
  aquariumId: null,
  connectToken: null,
  connectedToOwnAquarium: false,
  fishys: [],
  fishyLoopRunning: false,
  capturedFishy: {},
  pendingUserAction: false,
  setConnectedToOwnAquarium: connectedToOwnAquarium => {
    events.track ('Connected to own aquarium');
    return set({connectedToOwnAquarium})
  },
  addFishy: async (fishy) => {

    const state = useFishyStore.getState();
    const user = useUserStore.getState().serverUser;

    if (!state.connectedToOwnAquarium && state.connectToken) { //user connected to an external aquarium
      const res = await uploadFishy({connectToken: state.connectToken, blob: fishy.blob, maskUrl: fishy.maskUrl});
      if (res.error){ //only if there is an error uploading, add it locally.
        set ({fishys: state.fishys.concat([fishy])});
        if (res.error === 'aquarium-limit')
          return {status: 'external-aquarium-limit'}
        else 
          return {status: 'error'}
      }
    }
    else if (!user && state.fishys.length >= config.tiers['free-anonymous'].fishys) {
      events.track (`User reached free tier limit`);
      set({pendingUserAction: 'login'});
      return {status: 'login'};
    }
    else if (state.aquariumId){ //own user aquarium
        let res;
        res = await uploadFishy({aquariumId: state.aquariumId, blob: fishy.blob, maskUrl: fishy.maskUrl});
        if (res.error) {
          if (res.error === 'aquarium-limit') {
            events.track (`User reached free tier limit`);
            set({pendingUserAction: 'upgrade'});
            return {status: 'upgrade'}
          }
          else {
            set ({fishys: state.fishys.concat([fishy])});
            return {status: 'error'}
          }
        }
      }
    else { // not logged in user, and not connected to external aquarium
      events.track ('User scanned local Fishy')
      return set ({fishys: state.fishys.concat([fishy])});
    }
    
  },
  removeFishy: async (fileUrl) => {
    const state = useFishyStore.getState();
    try {
      const fishy = state.serverFishys.find(fishy => fishy.fileUrl === fileUrl);
      if (fishy?.fileName) {
        const res = await api.removeFishy({fileName: fishy.fileName, aquariumId: state.aquariumId});
        // set({fishys: state.serverFishys.filter(f => f.fileUrl !== fileUrl)});
        getFishys(state.aquariumId);
        console.log(`[fishyStore] fishy ${fileUrl} removed successfully`);
      }
      else {
        const fishy = state.fishys.find(fishy => fishy.fileUrl === fileUrl);
        if (!fishy) return;
        set({fishys: state.fishys.filter(f => f.fileUrl !== fileUrl)});
      }
    }
    catch(ex) {
      console.error(`[fishyStore] Remove fishy Error: ${ex}`);
      throw (ex);
    }

  },
  serverFishys: [],
  setCapturedFishy: fishy => set({capturedFishy: fishy}),
  setAquariumId: aquariumId => {
    set({aquariumId});
    getFishyLoop(aquariumId);
  },
  setConnectToken: connectToken => {
    set({connectToken});
    console.log({connectToken});
    if (!useFishyStore.getState().aquariumId)
      getFishyLoopConnectToken(connectToken);
  },
  setServerFishys: serverFishys => set({serverFishys}),
  setFishyLoopRunning: fishyLoopRunning => set({fishyLoopRunning}),
  checkAndSetAquariumId: async (aquariumId) => {
    try {
      events.track (`Trying to connect to Aquarium`, {aquariumId, via: 'aquariumId'});
      const res = await api.isAquariumExists(aquariumId);
      if (res.data?.connectToken) {
        events.track (`Connected to Aquarium`, {aquariumId, connectToken: res.data?.connectToken, via: 'aquariumId'});
        useFishyStore.getState().setAquariumId(aquariumId);
        useFishyStore.getState().setConnectToken(res.data?.connectToken)
        return true;
      }
      else {
        useFishyStore.getState().setAquariumId(null);
        useFishyStore.getState().setConnectToken(null);
        return false;
      }
    }
    catch (ex) {
      console.log(ex);
      return false;
    }
  },
  checkAndSetConnectToken: async (connectToken) => {
    try {
      events.track (`Trying to connect to Aquarium`, {connectToken, via: 'connectToken'});
      const res = await api.isAquariumExistsConnectToken(connectToken);
      if (res.data?.connectToken) {
        useFishyStore.getState().setConnectToken(connectToken);
        events.track (`Connected to Aquarium`, {connectToken, via: 'connectToken'});
        return true;
      }
      else {
        useFishyStore.getState().setConnectToken(null);
        return false;
      }
    }
    catch (ex) {
      console.log(ex);
      return false;
    }
  }
}));

const _getFishys = (apiRes) => {
  const {fishys, connectToken} = apiRes?.data || {};
  const state = useFishyStore.getState();
  if (JSON.stringify(state.serverFishys) !== JSON.stringify(fishys))
    
    ////halloween
    fishys.some(fishy => {
      if (fishy.maskUrl.includes('fish9') && !state.serverFishys.some(serverFishy => fishy.fileName === serverFishy.fileName))
        useHallowenStore.getState().setHalloweenFishyUploaded();
  })
  //////
    state.setServerFishys(fishys);
  if (connectToken != state.connectToken)
    state.setConnectToken(connectToken);
}

const getFishys = async (aquariumId) => {
  try {
      const res = await api.getFishys(aquariumId);
      _getFishys(res);
  }
  catch (ex) {
      //show message
      console.error (ex);
  }
};

const getFishysConnectToken = async (connectToken) => {
  try {
      const res = await api.getFishysConnectToken(connectToken);
      _getFishys(res);
  }
  catch (ex) {
      //show message
      console.error (ex);
  }
};

function getFishyLoop(aquariumId) {
  if (!useFishyStore.getState().fishyLoopRunning){
    getFishys(aquariumId);
    useFishyStore.getState().setFishyLoopRunning (true);
    setInterval (() => getFishys(aquariumId), config.fishySyncDuration);
  }
}

function getFishyLoopConnectToken(connectToken) {
  if (!useFishyStore.getState().fishyLoopRunning){
    getFishysConnectToken(connectToken);
    useFishyStore.getState().setFishyLoopRunning (true);
    setInterval (() => getFishysConnectToken(connectToken), config.fishySyncDuration);
  }
}

export default useFishyStore;