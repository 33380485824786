import confetti from 'canvas-confetti';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Sound from '../../../lib/sound.js';
import Keyframes from "../../Keyframes.jsx";
import Fish from "../Fish/Fish.jsx";
import styles from "./AnimatedFish.module.scss";
import FishContextMenu from "./FishContextMenu/FishContextMenu.jsx";

function random(from, to) {
    return Math.random() * (to - from) + from;
}

function generateVerticalAnimationKeyframes(height){
    const keyFrames = {
        "0": {
            "transform": "translateY(0) rotate(0deg)",
        },
        "100%": {
            "transform": `translateY(${random(30,60)}px) rotate(-${random(5,15)}deg)`,
        }
    }
    return keyFrames
}

function generateHorizontalAnimationKeyframes(width) {
    const direction = Math.random() > 0.5 ? "left" : "right";
    const keyFrames = {
        "0%": {
            [direction]: "100%",
            rotate: direction === "right" ? "y 180deg" : "0deg"
        },
        "50%": {
            [direction]: `-${width}px`,
            rotate: direction === "right" ? "y 180deg" : "0deg"
        },
        "51%": {
            [direction]: `-${width}px`,
            rotate: direction === "right" ? "0deg" : "y 180deg"
        },
        "100%": {
            [direction]: `calc(100% + ${width}px)`,
            rotate: direction === "right" ? "0deg" : "y 180deg"
        }
    }
    return keyFrames
}

function generateFishAnimationKeyframes() {
    const keyFrames = {
        "0%": {
            "rotate": "y 20deg",
            "scale": "0.95"
        },
        "50%": {
            "rotate": "y 0deg",
            "scale": "1"
        },
        "100%": {
            "rotate": "y -20deg",
            "scale": "1.05"
        }
    }
    return keyFrames
}

function AnimatedFish({ fileUrl, maskUrl, focusable, isNew }){

    // const [timeToRefresh, refresh] = useState(0);
    const [travels, setTravels] = useState(1);
    const width = useMemo (() => random(130, 250), []);
    const [focused, setFocused] = useState(false);

    const verticalAnimationName = useMemo (() => `fish-animation-vertical-${Date.now()}`, [travels]);
    const horizontalAnimationName = useMemo (() => `fish-animation-horizontal-${Date.now()}`, [travels]);
    const FishAnimationName = useMemo (() => `fish-animation-${Date.now()}`, [travels]);
    const verticalAnimationKeyframes  = useMemo(() => generateVerticalAnimationKeyframes(), [travels]);
    const horizontalAnimationKeyframes  = useMemo(() => generateHorizontalAnimationKeyframes(width), [travels]);
    const fishAnimationKeyframes  = useMemo(() => generateFishAnimationKeyframes(), [travels]);
    const horizontalAnimationDuration = useMemo (() => random(Math.pow(window.innerWidth / 350, 1.8) + 6, Math.pow(window.innerWidth / 350, 1.8) + 12), [travels]);
    const fishStyle = useMemo(() => ({
        animationName: FishAnimationName,
        animationDuration: `${random(1,4)}s`,
        animationIterationCount: "infinite",
        animationDirection: "alternate",
        animationTimingFunction: "ease-in-out",
        width,
        position: "relative"
    }), [travels]);
    const verticalStyle = useMemo(() => ({
        animationName: verticalAnimationName,
        animationDuration: `${random(3,5)}s`,
        animationIterationCount: "infinite",
        animationDirection: "alternate",
        animationTimingFunction: "ease-in-out",
        width
    }), [travels]);
    const horizontalStyle = useMemo(() => ({
        animationName: horizontalAnimationName,
        animationDuration: `${horizontalAnimationDuration}s`,
        animationIterationCount: "1",
        animationDirection: "normal",
        animationTimingFunction: "linear",
        animationFillMode: "forwards",
        top: `${random(0,80)}%`,
        transformOrigin: "center",
        width
    }), [horizontalAnimationDuration])

    const horizontalAnimationEnd = useCallback(() => {
        setTravels(travels => travels * -1);
    }, [travels]);

    const fishOnClick = useCallback(() => {
        if (!focusable) return;
        Sound.waterDrop();
        if (focused) setFocused(false);
        else setFocused(true);
    }, [fileUrl, focusable, focused])

    //confetti
    useEffect (() => {
        if (!isNew) return;
        const y = parseFloat(horizontalStyle.top.replace('%')) / 100;
        const left = !!horizontalAnimationKeyframes["0%"]["left"]

        const t = setTimeout(() => 
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y, x: left ? 1 : 0 },
                angle:  left ?  180 : 0,
            })
        , 1500);

        return () => clearTimeout(t)

    }, [isNew]);

    return (
        <div className={`${styles.fishContainer} ${focusable ? styles.clickable : ''} ${focused ? styles.focused : ''}`}>
            <Keyframes animationName={horizontalAnimationName} keyframes={horizontalAnimationKeyframes}>
                <Keyframes animationName={verticalAnimationName} keyframes={verticalAnimationKeyframes}>
                    <Keyframes animationName={FishAnimationName} keyframes={fishAnimationKeyframes}>
                        <div className={styles.horizontalMovement} style={horizontalStyle} onAnimationEnd={horizontalAnimationEnd}>
                            <div className={styles.verticalMovement} style={verticalStyle}>
                                <div style={fishStyle}>
                                    <Fish fileUrl={fileUrl} maskUrl={maskUrl} width={width} fishOnClick={fishOnClick} className={isNew ? styles.isNew : null}/>
                                    {!focusable ? null :
                                        <div className={`${styles.contextMenu} ${focused ? styles.showContextMenu : ''}`}>
                                            <FishContextMenu fileUrl={fileUrl} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Keyframes>
                </Keyframes>
            </Keyframes>
        </div>
    );
}

export default AnimatedFish;

