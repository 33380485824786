import { forwardRef } from "react";
import styles from "./Fish.module.scss";

export default forwardRef( ({fileUrl, maskUrl, width, className, fishOnClick}, ref ) => {
    const fishStyle = {
        WebkitMaskImage: `url(${maskUrl})`,
        width
    }
    return (
        <img ref={ref} src={fileUrl} className={`${styles.fish} ${className || ''}`} style={fishStyle} onClick={fishOnClick}/>
    )
})