import { Tooltip } from '@chakra-ui/react';
import { faEnvelope, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import event from "../../../../lib/analytics";
import Sound from '../../../lib/sound';
import useRouter from "../../../store/useRouter";
import Login from "../Login/Login";
import styles from "./Menu.module.scss";

export function MenuItem({key, linkTo, children, onClick}) {

    const baseUrl = useRouter(state => state.base);
    // const location = useLocation();
    // const path = location.pathname === '/' ? '' : location.pathname;

    const _onClick = useCallback((...args) => {
        event('menuItemClicked', {key, linkTo});
        onClick && onClick(...args);
    }, []) 

    return (
        <Link to={`${baseUrl === "/" ? '' : baseUrl}${linkTo}`}>
            <li key={key} onClick={_onClick}>{children}</li>
        </Link> 
    )
}

export default function Menu ({displayed, closeMenuClicked, children}) {

    const {t} = useTranslation();

    useEffect(() => {
        Sound.swoosh();
    }, [displayed]);

    return (
        <aside className={`${styles.menu} ${displayed ? styles.displayed : ''}`}>
            <FontAwesomeIcon icon={faXmark} size="2x" className={styles.closeIcon} onClick={() => closeMenuClicked()}/>
            <Login />
            {/* <div className={styles.dropDowns}>
                <AccountDropDown />
                <AquariumDropDown />
            </div> */}
            <ul className={styles.items}>
                {children.map (child => React.cloneElement(child, {onClick: () => {
                    Sound.waterDrop();
                    child.props.onClick && child.props.onClick();
                    closeMenuClicked()
                }}))}
            </ul>
            <ul className={styles.bottomMenu} >
                <Tooltip label={t('contactUs')}>
                    <a href="mailto:contact@swimfishy.com"><FontAwesomeIcon icon={faEnvelope} size="2x"></FontAwesomeIcon></a>
                </Tooltip>
            </ul>
        </aside>
    )
    
}