import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, IconButton, useDisclosure, useToast } from "@chakra-ui/react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Sound from "../../../../lib/sound";
import useFishyStore from "../../../../store/fishys";


export default function FishContextMenu({fileUrl}) {

    const cancelRef = useRef();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { t }  = useTranslation();
    const [isLoading, setIsLoading] = useState();
    const removeFishy = useFishyStore(state => state.removeFishy);
    const toast = useToast();
    
    const deleteFishyClicked = useCallback((fishyUrl) => {
        Sound.tap();
        onOpen();
    }, []);

    const deleteFishy = useCallback(async () => {
        Sound.tap();
        setIsLoading(true);
        try {
            await removeFishy(fileUrl);
            onClose();
            Sound.success();
            toast({
                description: t('removeFishySuccess'),
                duration: 4000,
                type: 'success'
              });
        }
        catch (ex){
            toast({
                description: t('removeFishyError'),
                duration: 4000,
                type: 'error'
              })
        }
        finally {
            setIsLoading(false);
        }
    }, [])

    return (
        <>
            <IconButton colorScheme='red' icon={<FontAwesomeIcon key={'deleteFishy'} icon={faTrash} />} onClick={deleteFishyClicked} />
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                <AlertDialogHeader>{t('removeFishyHeader')}</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>{t('removeFishyBody')}</AlertDialogBody>
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={() => Sound.tap() && onClose()}>
                        {t('no')}
                    </Button>
                    <Button isLoading={isLoading} colorScheme={'red'} ml={3} onClick={deleteFishy}>
                        {t('yes')}
                    </Button>
                </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}